/* eslint-disable @typescript-eslint/camelcase */
import {
  BalanceEntities,
  BalanceDetailData
} from "@/domain/entities/BalanceLimit";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { BalanceLimitPresenter } from "../presenters/BalanceLimitPresenter";
import { EditBalanceApiRequest } from "@/data/payload/api/BalanceLimitRequest";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface BalanceState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  balanceData: BalanceEntities;
  balanceDetail: BalanceDetailData;
  isError: boolean;
  isErrorEdit: boolean;
  isRoute: boolean;
  openModal: boolean;
  errorCause: string;
  description: string;
  minBalance: number;
}
@Module({ namespaced: true, dynamic: true, store, name: "balance-limit" })
class BalanceStore extends VuexModule implements BalanceState {
  public isLoading = false;
  public isLoadingDetail = false;
  public balanceData = new BalanceEntities(new Pagination(1, 10), []);
  public balanceDetail = new BalanceDetailData(
    0,
    "",
    "",
    "",
    0,
    0,
    0,
    0,
    0,
    0,
    "",
    "",
    "",
    "",
    ""
  );
  public search = "";
  public status = "";
  public page = 1;
  public limit = 10;
  public isError = false;
  public isErrorEdit = false;
  public isRoute = false;
  public openModal = false;
  public errorCause = "";
  public openModalSuccess = false;
  public description = "";
  public minBalance = 0;

  @Action
  public getBalanceList(params: { page: number; limit: number }) {
    this.setLoading(true);
    const presenter = container.resolve(BalanceLimitPresenter);
    presenter
      .getBalanceList(params.page, params.limit)
      .then((res: BalanceEntities) => {
        this.setBalanceList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getBalanceDetail(params: { id: any }) {
    this.setLoadingDetail(true);
    const presenter = container.resolve(BalanceLimitPresenter);
    presenter
      .getBalanceDetail(params.id)
      .then((res: BalanceDetailData) => {
        this.setBalanceDetail(res);
        this.setRoute(res.status.toLowerCase() === "active");
        this.setDescription(res.description);
        this.setMinBalance(res.minBalance);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingDetail(false);
      });
  }

  @Action
  public editBalance(params: {
    limitId: number;
    limitDescription: string;
    limitMinBalance: number;
    limitBorder: number;
    status: string;
    limitMinimumWithdrawAmount: number;
    isCodDelivery: boolean;
    limitMinBalanceMY: number;
    limitBorderMY: number;
    limitMinimumWithdrawAmountMY: number;
  }) {
    this.setLoadingDetail(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BalanceLimitPresenter);
    presenter
      .editBalance(
        new EditBalanceApiRequest(
          params.limitId,
          params.limitDescription,
          params.limitMinBalance,
          params.limitBorder,
          params.status,
          params.limitMinimumWithdrawAmount,
          params.isCodDelivery,
          params.limitMinBalanceMY,
          params.limitBorderMY,
          params.limitMinimumWithdrawAmountMY
        )
      )
      .then(() => {
        this.setOpenModalSuccess(true);
        this.setErrorEdit(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Ubah Balance Limit Rules Gagal", () => {
            MainAppController.closeErrorMessage();
            this.editBalance(params);
          })
        );
      })
      .finally(() => {
        this.setLoadingDetail(false);
        this.setModalOpenSave(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public async setNextPage() {
    await this.nextPage();
    this.getBalanceList({
      page: this.page,
      limit: 10
    });
  }

  @Action
  public async setPrevPage() {
    if (this.page !== 1) {
      await this.prevPage();
      this.getBalanceList({
        page: this.page,
        limit: 10
      });
    }
  }

  @Action
  public setFirstPage() {
    this.setFirst();
  }

  @Action
  public setPageAction(value: number) {
    this.setPage(value);
    this.getBalanceList({
      page: value,
      limit: 10
    });
  }

  @Action
  public handleError() {
    this.setErrorEdit(false);
  }

  @Mutation
  public setModalOpenSave(bool: boolean) {
    this.openModal = bool;
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setLoadingDetail(isLoading: boolean) {
    this.isLoadingDetail = isLoading;
  }

  @Mutation
  private setBalanceList(data: BalanceEntities) {
    this.balanceData = data;
  }

  @Mutation
  private setBalanceDetail(data: BalanceDetailData) {
    this.balanceDetail = data;
  }

  @Mutation
  public setRoute(boolean: boolean) {
    this.isRoute = boolean;
  }

  @Mutation
  private async setPage(page: number) {
    this.page = page;
  }

  @Mutation
  private async nextPage() {
    if (this.page >= 1) {
      this.page = Number(this.page) + 1;
    }
  }

  @Mutation
  private async prevPage() {
    this.page = Number(this.page) - 1;
  }

  @Mutation
  private async setFirst() {
    this.page = 1;
  }

  @Mutation
  private setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  public setErrorEdit(bool: boolean) {
    this.isErrorEdit = bool;
  }

  @Mutation
  private setErrorCause(str: string) {
    this.errorCause = str;
  }

  @Mutation
  public setOpenModalSuccess(value: boolean) {
    this.openModalSuccess = value;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setDescription(value: string) {
    this.description = value;
  }

  @Mutation
  public setMinBalance(value: number) {
    this.minBalance = value;
  }
}

export const BalanceLimitController = getModule(BalanceStore);
