
import { PropType } from "vue";
import { Vue, Options } from "vue-class-component";
import formatInputMoney from "@/app/infrastructures/misc/common-library/FormatInputMoney";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";
import parseInputFloatToFloat from "@/app/infrastructures/misc/common-library/ParseInputFloatToFloat";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BalanceLimitController } from "@/app/ui/controllers/BalanceLimitController";
import { BeneficiaryAccountController } from "@/app/ui/controllers/BeneficiaryAccountController";
import { AuthController } from "@/app/ui/controllers/AuthController";
import debounce from "lodash/debounce";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { FlagsPermissionSaldo } from "@/feature-flags/flags-permission-saldo";
import { FlagsPermissionCodBalance } from "@/feature-flags/flags-permission-cod-balance";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
@Options({
  components: {},
  props: {
    onClose: {
      default: Function as PropType<() => void>,
      type: Function
    },
    balanceType: {
      default: "balance",
      type: String
    }
  },
  computed: {
    isVisibleRequest() {
      if (this.props.balanceType === "cod") {
        return FlagsPermissionCodBalance.permission_cod_balance_withdraw_request_detail.isEnabled();
      }
      return FlagsPermissionSaldo.permission_saldo_withdraw_request_detail.isEnabled();
    }
  }
})
export default class ModalWithdraw extends Vue {
  props: any = this.$props;
  created() {
    this.getBalanceList();
    this.getBeneficiaryAccount();
  }

  get isLoadingData() {
    return this.loadingBeneficiary || this.loadingBalanceRules;
  }

  form = {
    accountNumber: "" as any
  };

  amountWithdrawal = "";
  passwordType = "password";
  iconType = "eye-slash";
  password = "";

  get isDisabled() {
    return (
      this.amountWithdrawal === "" ||
      !this.isPasswordValidate ||
      this.errorAmountWithdrawal ||
      this.beneficiaryModelValue === "-"
    );
  }

  get errorAmountWithdrawal() {
    return this.errorMinWithdraw || this.errorMinBalance;
  }
  get errorMinWithdraw() {
    const minWithdrawal = Number(this.balanceRules[0]?.minWithdraw);
    const amountToWithdraw = parseInputFloatToFloat(this.amountWithdrawal);
    return amountToWithdraw < minWithdrawal;
  }
  get errorMinBalance() {
    const minBalance = Number(this.balanceRules[0]?.minBalance);
    const amountToWithdraw = parseInputFloatToFloat(this.amountWithdrawal);
    const balanceAvailable = this.balance;
    if (this.props.balanceType === "balance") {
      return balanceAvailable - amountToWithdraw < minBalance;
    }
    return amountToWithdraw > balanceAvailable;
  }

  showPassword() {
    if (this.passwordType === "password" && this.iconType === "eye-slash") {
      this.passwordType = "text";
      this.iconType = "eye";
    } else {
      this.passwordType = "password";
      this.iconType = "eye-slash";
    }
  }

  // Only number
  formatted() {
    if (/^0/.test(this.amountWithdrawal)) {
      this.amountWithdrawal = this.amountWithdrawal.replace(/^0/, "");
    } else {
      this.amountWithdrawal = this.amountWithdrawal.replace(/\D+/g, "");
      this.amountWithdrawal = formatInputMoney(this.amountWithdrawal);
    }
  }
  formatPrice(val: any) {
    return formatPrice(val);
  }

  // balance
  get balance() {
    return this.props.balanceType === "balance"
      ? BalanceController.balanceData.walletBalance
      : BalanceController.balanceData.walletBalanceCod;
  }
  get balanceTitle() {
    return this.props.balanceType === "balance"
      ? "Saldo Tersedia"
      : "Penghasilan COD";
  }

  get accountType() {
    if (AccountController.accountData.account_type === "partner") {
      return AccountController.accountData.account_type_detail.type;
    }
    return AccountController.accountData.account_type;
  }

  // get limit rules
  async getBalanceList() {
    BalanceLimitController.getBalanceList({
      page: 1,
      limit: 10
    });
  }
  get loadingBalanceRules() {
    return BalanceLimitController.isLoading;
  }
  get balanceData() {
    return BalanceLimitController.balanceData.data;
  }
  get balanceRules() {
    if (this.accountType === "pos") {
      return this.balanceData.filter((item: any) => {
        return (
          item.appliedTo.toLowerCase() === this.accountType &&
          item.metaCodDelivery ===
            this.dataProfile.account_type_detail.isCodDelivery
        );
      });
    }
    return this.balanceData.filter((item: any) => {
      return item.appliedTo.toLowerCase() === this.accountType;
    });
  }

  // get beneficiary account
  async getBeneficiaryAccount() {
    BeneficiaryAccountController.getBeneficiaryAccList({
      page: 1,
      limit: 10,
      bankAccType: "beneficiary",
      actortype: "",
      actorId: ""
    });
  }
  get loadingBeneficiary() {
    return BeneficiaryAccountController.isLoading;
  }
  get beneficiaryDetail() {
    return BeneficiaryAccountController.beneficiaryList.data.filter(
      (beneficiary: any) => {
        return beneficiary.bankAccountType === "beneficiary";
      }
    );
  }
  get beneficiaryModelValue() {
    if (this.beneficiaryDetail.length > 0) {
      return `${this.beneficiaryDetail[0]?.bankAccountName} - ${this.beneficiaryDetail[0].bankAccountNumber}`;
    }
    return "-";
  }

  get dataProfile() {
    return AccountController.accountData;
  }
  isPasswordValidate = false;
  validatePasswordAccount = debounce(async (e: any) => {
    const value = e.target.value;
    if (value.length === 0) {
      AuthController.setErrorPassword(false);
      this.isPasswordValidate = false;
      return;
    }
    this.isPasswordValidate = await AuthController._onCheckPassword({
      email: this.dataProfile.username || this.dataProfile.email,
      password: e.target.value
    });
  }, 500);
  get isWrongPassword() {
    return AuthController.isErrorWrongPassword;
  }

  async onCallWithdraw() {
    if (this.isDisabled) {
      return;
    }
    const response = await BalanceController.withdrawBalance({
      amount: parseInputFloatToFloat(this.amountWithdrawal), version: AccountController.accountData.isPosAccount ? FlagsMedusa.config_version_endpoint_withdraw_pos.getValue() : 'v1'
    });
    if (response) {
      this.props.onClose();
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Permintaan Berhasil !",
          message:
            "Permintaan penarikan saldo berhasil. Mohon menunggu proses pencairan dalam waktu maks. 14 hari kerja.",
          textSuccess: "OK",
          onSubmit: () => this.onSubmitSuccessModal(),
          image: "image-modal-success"
        })
      );
      await BalanceController.getBalance(true);
    }
  }

  onSubmitSuccessModal() {
    MainAppController.closeMessageModal();
    this.props.onClose();
  }

  get activeCashbackConfig() {
    return AccountController.activeCashback.data;
  }

  get isAnyCashbackConfigActive() {
    return AccountController.accountData.isPosAccount && this.activeCashbackConfig.ccbId && this.activeCashbackConfig.ccbStatus.toLowerCase() === 'active'
  }
}
