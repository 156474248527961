/* eslint-disable @typescript-eslint/camelcase */
import { EditBalanceRequestInterface } from "../contracts/BalanceLimitRequest";

export class EditBalanceApiRequest implements EditBalanceRequestInterface {
  private readonly limit_rule_id: number;
  private readonly limit_rule_description: string;
  private readonly limit_rule_min_balance: number;
  private readonly limit_rule_limit_border: number;
  private readonly limit_rule_status: string;
  private readonly limit_rule_minimum_withdraw_amount: number;
  private readonly is_cod_delivery: boolean;
  private readonly limit_rule_min_balance_currency_myr: number;
  private readonly limit_rule_limit_border_currency_myr: number;
  private readonly limit_rule_min_withdraw_amount_currency_myr: number;

  constructor(
    limit_rule_id: number,
    limit_rule_description: string,
    limit_rule_min_balance: number,
    limit_rule_limit_border: number,
    limit_rule_status: string,
    limit_rule_minimum_withdraw_amount: number,
    is_cod_delivery: boolean,
    limit_rule_min_balance_currency_myr: number,
    limit_rule_limit_border_currency_myr: number,
    limit_rule_min_withdraw_amount_currency_myr: number,
  ) {
    this.limit_rule_id = limit_rule_id;
    this.limit_rule_description = limit_rule_description;
    this.limit_rule_min_balance = limit_rule_min_balance;
    this.limit_rule_limit_border = limit_rule_limit_border;
    this.limit_rule_status = limit_rule_status;
    this.limit_rule_minimum_withdraw_amount = limit_rule_minimum_withdraw_amount;
    this.is_cod_delivery = is_cod_delivery;
    this.limit_rule_min_balance_currency_myr = limit_rule_min_balance_currency_myr;
    this.limit_rule_limit_border_currency_myr = limit_rule_limit_border_currency_myr;
    this.limit_rule_min_withdraw_amount_currency_myr = limit_rule_min_withdraw_amount_currency_myr;
  }

  public toJSON(): string {
    return JSON.stringify({
      limit_rule_id: this.limit_rule_id,
      limit_rule_description: this.limit_rule_description,
      limit_rule_min_balance: this.limit_rule_min_balance,
      limit_rule_limit_border: this.limit_rule_limit_border,
      limit_rule_min_withdraw_amount: this.limit_rule_minimum_withdraw_amount,
      limit_rule_min_balance_currency_myr: this.limit_rule_min_balance_currency_myr,
      limit_rule_limit_border_currency_myr: this.limit_rule_limit_border_currency_myr,
      limit_rule_min_withdraw_amount_currency_myr: this.limit_rule_min_withdraw_amount_currency_myr,
      limit_rule_status: this.limit_rule_status,
      meta_data_limit_rule: {
        is_cod_delivery: this.is_cod_delivery
      }
    });
  }
}
